<template>
	<div class="container">
		<div class="contact-container">
			<h2>Kontakt Oss</h2>
			<p>
				Du kan kontakte oss ved å fylle ut skjemaet nedenfor. <br />
				Vi vil komme tilbake til deg så fort som mulig.
			</p>
			<div class="contact-info">
				<div>Du kan også nå oss på:</div>
				<div>
					+47 90 66 28 80
					<a href="mailto:post@ellingsenbyggeservice.no"
						><img src="@/assets/icons/phone.png"
					/></a>
				</div>
				<div>
					post@ellingsenbyggservice.no<a
						href="mailto:post@ellingsenbyggeservice.no"
						><img src="@/assets/icons/email.png"
					/></a>
				</div>

				<div></div>
			</div>
			<form @submit.prevent="sendEmail">
				<div class="contact-form-grid">
					<div class="contact-col-1">
						<label>Navn</label>
						<input
							type="text"
							v-model="name"
							name="name"
							placeholder="Your Name"
						/>
						<label>Epost</label>
						<input
							type="email"
							v-model="email"
							name="email"
							placeholder="Your Email"
						/>
						<label>Telefon</label>
						<input
							type="tlf"
							v-model="tlf"
							name="tlf"
							placeholder="Ditt telefonnummer"
						/>
						<label>Anledning</label>
						<select name="occasion" id="occasion">
							<option selected="selected" disabled
								>Velg anledning</option
							>
							<option value="Bad">Bad</option>
							<option value="Kjøkken">Kjøkken</option>
							<option value="Oppussing">Oppussing</option>
							<option value="Restaurering">Restaurering</option>
							<option value="Spesialrom">Spesialrom</option>
							<option value="Tilbygg">Tilbygg</option>
							<option value="Totalrenovering"
								>Totalrenovering</option
							>
							<option value="Treverksmøbler"
								>Treverksmøbler</option
							>
							<option value="Vaskrom">Vaskerom</option></select
						>
					</div>
					<div class="contact-col-2">
						<label>Melding</label>
						<textarea
							name="message"
							v-model="message"
							cols="30"
							rows="5"
							placeholder="Message"
						>
						</textarea>
					</div>
				</div>

				<input type="submit" value="Send Melding" />
			</form>
		</div>
	</div>
</template>

<script>
import emailjs from 'emailjs-com'
export default {
	data() {
		return {
			name: '',
			email: '',
			message: '',
			occasion: '',
			tlf: '',
		}
	},
	methods: {
		sendEmail(e) {
			try {
				emailjs.sendForm(
					'service_zw2h3yc',
					'template_famb42j',
					e.target,
					'user_YueHw7LLz9Af6WimpUx60',
					{
						name: this.name,
						email: this.email,
						message: this.message,
						occasion: this.occasion,
						tlf: this.tlf,
					}
				)
			} catch (error) {
				console.log({ error })
			}
			// Reset form field
			this.name = ''
			this.email = ''
			this.message = ''
			this.tlf = ''
			this.occasion = ''
		},
	},
}
</script>

<style scoped>
.contact-container {
	text-align: center;
	width: 100%;
	margin: 0 auto;
	padding-top: 5%;
	padding-bottom: 13%;
}

.contact-container p {
	line-height: 160%;
	font-size: 140%;
	font-weight: 140%;
}

.contact-container h2 {
	padding-bottom: 3%;
}

.contact-info {
	padding-top: 3%;
	font-size: 120%;
	line-height: 150%;
}

.contact-info img {
	max-height: 1.35vh;
	margin-left: 1%;
}

.container {
	display: block;
	margin: auto;
	text-align: center;
	padding: 20px;
	width: 50%;
}

.contact-form-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	padding-top: 7%;
	padding-bottom: 1%;
	font-size: 140%;
	font-weight: 140%;
}

.contact-col-1 {
	max-width: 90%;
}

.contact-col-2 {
	height: 100%;
}

textarea {
	height: 87.5%;
}

label {
	float: left;
}

input[type='text'],
[type='email'],
[type='tlf'],
select,
textarea {
	width: 100%;
	padding: 15px;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
	font-size: 80%;
	margin-top: 6px;
	margin-bottom: 16px;
	resize: vertical;
}

input[type='submit'] {
	background-color: #000;
	color: white;
	padding: 12px 30px;
	border: none;
	border-radius: 30px;
	cursor: pointer;
	font-size: 1.7vh;
}

@media only screen and (max-width: 1100px) {
	.container {
		width: 70%;
	}
}

@media only screen and (max-width: 800px) {
	.contact-form-grid {
		display: grid;
		grid-template-columns: 1fr;
		padding-top: 7%;
		padding-bottom: 4%;
	}
	.container {
		display: block;
		margin: auto;
		text-align: center;
		padding: 20px;
		width: 80%;
	}
	input[type='submit'] {
		background-color: #000;
		color: white;
		padding: 12px 30px;
		border: none;
		border-radius: 30px;
		cursor: pointer;
		font-size: 1.7vh;
	}
}
</style>
